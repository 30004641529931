import React from "react";

const GoogleMap = ({ extraClassName, map }) => {
  return (
    <div className={`google-map__${extraClassName}`}>
      <iframe
        title="template google map"
        src={map}
        className={`map__${extraClassName}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleMap;
